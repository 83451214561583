<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card title="基本信息" :bordered='false'>
      <a-descriptions :column="4">
        <a-descriptions-item label='品牌公司名称'>{{profile.brandName}}</a-descriptions-item>
        <a-descriptions-item label='分公司'>{{profile.companyNameStr}}</a-descriptions-item>
        <a-descriptions-item label='成员人数'>{{profile.areaList.length}}</a-descriptions-item>
        <a-descriptions-item label='总业绩'>{{profile.totalPerformance}}</a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card title="成员业绩" style="margin-top: 20px;" :bordered='false'>
      <a-descriptions :column="1">
        <a-descriptions-item v-for="(item, index) in profile.memberPerformanceList" :key="index" :label='item.memberMasterName'>{{item.memberPerformance || 0}}</a-descriptions-item>
      </a-descriptions>
    </a-card>    
  </page-header-wrapper>
</template>

<script>
import storage from '@/utils/storage.js';

export default {
  name: 'PerformanceAreaEdit',

  data() {
    return {
      profile: {},
    }
  },
  computed: {
    // id () {
    //   return this.$route.query.id;
    // }
  },
  created() {
    this.id = this.$route.query.id;
    this.handleInitial(this.id)
  },
  activated()
  {
    this.id = this.$route.query.id;
    this.handleInitial(this.id);
    // debugger;
  },
  methods: {
    handleInitial(id) {
      const result = storage.get("performanceAreaEdit_" + id);
      if (result) {
        this.profile = result;
      } else {
        this.$router.replace("/performance/area/list");
      }
    },
  }
}
</script>
